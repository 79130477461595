const USER = "sfq_bo_user";

async function saveUser(newUser) {
  await localStorage.setItem(USER, JSON.stringify(newUser));
}

async function loadUser() {
  const user = await localStorage.getItem(USER);
  return JSON.parse(user);
}

async function clearUser() {
  await localStorage.removeItem(USER);
}

export default { loadUser, saveUser, clearUser };
