import React from "react";

function PasswordDisplayIcon({ isOpen }) {
  return isOpen ? (
    <svg xmlns="http://www.w3.org/2000/svg" data-name="Item icon view" viewBox="0 0 20 17.28">
      <path
        fill="#424242"
        d="M19.08 21.14l-2.31-2.39a9.7 9.7 0 01-9.4.09 11 11 0 01-3.1-2.59A13.77 13.77 0 012 12.5a13.8 13.8 0 013.71-5.17L3.63 5.19l1.29-1.33 15.45 16-1.28 1.33zM8.21 9.92a4.78 4.78 0 001.27 6.49 4.43 4.43 0 005 0L13.17 15a2.62 2.62 0 01-1.17.28 2.77 2.77 0 01-2.73-2.78 2.82 2.82 0 01.28-1.21zm11.39 6.47l-3.12-3.21A4.52 4.52 0 0012 7.81a3.26 3.26 0 00-.62.06L9 5.47a9.59 9.59 0 017.61.7 10.82 10.82 0 013.1 2.58A13.77 13.77 0 0122 12.5a14.34 14.34 0 01-2.4 3.9z"
        transform="translate(-2 -3.86)"
      ></path>
    </svg>
  ) : (
    <svg xmlns="http://www.w3.org/2000/svg" data-name="Item icon view" viewBox="0 0 20 15">
      <path
        fill="#424242"
        d="M12 20a9.48 9.48 0 01-4.63-1.17 11.15 11.15 0 01-3.1-2.58A13.82 13.82 0 012 12.5a14.07 14.07 0 012.27-3.75 11.15 11.15 0 013.1-2.58A9.48 9.48 0 0112 5a9.48 9.48 0 014.63 1.17 11.15 11.15 0 013.1 2.58A13.82 13.82 0 0122 12.5a14.07 14.07 0 01-2.27 3.75 11.15 11.15 0 01-3.1 2.58A9.48 9.48 0 0112 20zm0-12.19a4.69 4.69 0 000 9.38 4.69 4.69 0 000-9.38zm0 7.5a2.77 2.77 0 01-2.73-2.81 2.73 2.73 0 015.46-.15v.15A2.77 2.77 0 0112 15.31z"
        transform="translate(-2 -5)"
      ></path>
    </svg>
  );
}

export default PasswordDisplayIcon;
