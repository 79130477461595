import axios from "axios";
import storage from "../../utils/storage";

axios.interceptors.request.use(async (config) => {
  const user = await storage.loadUser();
  if (user && user.token) config.headers["Sfq-Token"] = user.token;

  return config;
});

axios.interceptors.response.use(
  (response) => response,
  async (error) => {
    if (error.response.status === 401 && !error.config.url.includes("Login")) {
      await storage.clearUser();
      window.location.reload();
    }

    return Promise.reject(error);
  },
);

// const baseUrl = "http://localhost:3000/";
const baseUrl = "https://api.ninjasgames.fr/";

const apiUrl = baseUrl + "";

const REST = {
  get(route, params = {}) {
    return axios.get(apiUrl + route, { params });
  },
  post(route, data) {
    return axios.post(apiUrl + route, data);
  },
  put(route, data) {
    return axios.put(apiUrl + route, data);
  },
  delete(route, data) {
    return axios.delete(apiUrl + route, { data });
  },
};

export default REST;
