import React from "react";
import { useTranslation } from "react-i18next";

import "./InputField.scss";

function InputField({
  fieldName,
  value,
  setValue,
  children,
  activeInputState,
  isPasswordHidden = false,
  errorMessage,
  setIsActive = () => {},
}) {
  const { t } = useTranslation();
  const [activeInput, setActiveInput] = activeInputState;

  return (
    <div className={`input-field input ${activeInput === fieldName && "input-focused"}`}>
      {value && value.length > 0 && t(`userInformations.${fieldName}`)}
      <div className="input-content">
        <input
          value={value || ""}
          onChange={(item) => setValue(item.target.value)}
          type={isPasswordHidden ? "password" : "text"}
          placeholder={t(`userInformations.${fieldName}`)}
          onFocus={() => {
            setActiveInput(fieldName);
            setIsActive(true);
          }}
          onBlur={() => {
            setActiveInput("");
            setTimeout(() => {
              setIsActive(false);
            }, 100);
          }}
        />
        {children}
      </div>
      <div className="error-message">{errorMessage}</div>
    </div>
  );
}

export default InputField;
