import React, { Suspense } from "react";
import { BrowserRouter, Route } from "react-router-dom";

import { Login } from "../components/pages";

const BackofficeLayout = React.lazy(() => import("../modules/backoffice/components/layouts/BackofficeLayout"));

export default function Routes() {
  return (
    <BrowserRouter>
      <Suspense fallback={<div></div>}>
        <Route exact path="/" component={Login} />
        <Route path="/backoffice" component={BackofficeLayout} />
      </Suspense>
    </BrowserRouter>
  );
}
